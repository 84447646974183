import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Layout, RandomOp, Typography } from '../../components';
import {
  MainTitle, MainPart, Figure, List,
} from '../../components/opComponents';

const ArthroseDesDoigts = () => (
  <Layout
    title="Arthrose des doigts : Diagnostic et traitement"
    description="L'arthrose des doigts peut avoir de multiples manifestations allant d'une simple raideur articulaire à une articulation fortement déviée."
    headerStyle="white"
  >
    <div className="flex flex-col px-4 py-16 mx-auto space-y-4 max-w-screen-mdx">
      <Link to="/chirurgie-de-la-main" className="flex flex-row items-center space-x-3 uppercase text-primary group">
        <FontAwesomeIcon className="transition-all duration-300 ease-in-out group-hover:text-link" icon="long-arrow-alt-left" />
        <Typography className="group-hover:text-link" weight="semiBold" transition="medium">Retour aux opérations de la main</Typography>
      </Link>
      <div className="flex flex-col space-y-6">
        <MainTitle currentOpTitle="Arthrose des doigts" />
        <Typography>
          L&apos;arthrose des doigts est une pathologie qui touche 65 % des Français de plus de
          60 ans*. Focus sur cette maladie des articulations et sur ses traitements.
        </Typography>
        <Typography>
          *Source :
          {' '}
          <Link to="https://www.inserm.fr/dossier/arthrose/" target="_blank">
            <Typography variant="span">
              https://www.inserm.fr/dossier/arthrose/
            </Typography>
          </Link>
        </Typography>
        <MainPart title="Focus sur l&apos;arthrose des doigts">
          <Typography>
            L&apos;arthrose des doigts est définie par la destruction du cartilage articulaire.
            Il existe deux sortes d&apos;arthrose :
          </Typography>
          <List>
            <List.Item>
              <Typography weight="bold">
                L&apos;arthrose des doigts primitive :
                {' '}
                <Typography variant="span">
                  le plus souvent héréditaire et à prédominance féminine.
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                L&apos;arthrose des doigts secondaires :
                {' '}
                <Typography variant="span">
                  le plus souvent post-traumatique.
                </Typography>
              </Typography>
            </List.Item>
          </List>
          <Typography>
            L&apos;arthrose digitale est parfois confondue avec l&apos;arthrite. Or, cette dernière
            est une atteinte articulaire secondaire à une pathologie inflammatoire (comme la
            polyarthrite rhumatoïde ou le rhumatisme psoriasique) ou infectieuse. Dans le cas
            de l&apos;arthrose, on a affaire à une dégradation du cartilage d&apos;origine
            mécanique.
          </Typography>
        </MainPart>
        <MainPart title="Les symptômes de l&apos;arthrose des doigts">
          <Typography>
            L&apos;apparition de l&apos;arthrose est souvent insidieuse. Elle peut concerner une ou
            plusieurs articulations de la main (Figure 1).
          </Typography>
          <Typography>
            Dans certains cas, le patient peut avoir une crise douloureuse et aiguë, due à une
            poussée inflammatoire d&apos;arthrose.
          </Typography>
          <Typography>
            Les signes cliniques peuvent être :
          </Typography>
          <List>
            <List.Item>
              <Typography weight="bold">
                Les douleurs articulaires :
                {' '}
                <Typography variant="span">
                  liées à la destruction du cartilage articulaire.
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                Le gonflement de l&apos;articulation :
                {' '}
                <Typography variant="span">
                  lié au gonflement de la synoviale et à la production
                  excessive de liquide articulaire.
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                L&apos;apparition de nodules :
                {' '}
                <Typography variant="span">
                  Ces formations arrondies, dures et inesthétiques sont secondaires à des becs
                  osseux (ostéophytes). On distingue deux sortes de nodules : les nodules
                  d&apos;Heberden au niveau de l&apos;articulation inter-phalangienne distale,
                  et les nodules de Bouchard au niveau de l&apos;articulation inter-phalangienne
                  proximale.
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                La désaxation progressive de l&apos;articulation :
                {' '}
                <Typography variant="span">
                  en rapport avec une destruction asymétrique de l&apos;articulation,
                  responsable de la déviation du doigt.
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                la perte de mobilité articulaire :
                {' '}
                <Typography variant="span">
                  qui est secondaire à la destruction de l&apos;articulation. Elle
                  peut aussi bien concerner la flexion et/ou l&apos;extension du doigt.
                </Typography>
              </Typography>
            </List.Item>
          </List>
          <Typography>
            On peut également ajouter des raideurs et des déformations des doigts (à cause
            du gonflement ou aux becs osseux par exemple).
          </Typography>
          <Figure
            maxWidth="max-w-[400px]"
            imageName="figure1-arthrosedesdoigts.jpg"
            caption="Figure 1. Arthrose de la main responsable de la déviation des doigts et de l&apos;apparition de nodules digitaux"
            currentOpTitle="Arthrose des doigts"
          />
        </MainPart>
        <MainPart title="Quels examens pour le diagnostic d&apos;arthrose des doigts ?">
          <Typography>
            Pour confirmer une suspicion d&apos;arthrose, il suffit de faire une radiographie
            (figure 2).
          </Typography>
          <Typography>
            Sur les clichés, on peut retrouver ces anomalies, révélateur de la maladie :
          </Typography>
          <List>
            <List.Item>
              <Typography weight="bold">
                Un pincement de l&apos;interligne articulaire :
                {' '}
                <Typography variant="span">
                  le liseré noir entre les deux phalanges, qui correspond au cartilage articulaire,
                  est plus fin que sur une articulation normale. Cela témoigne de la destruction du
                  cartilage articulaire.
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                Des géodes :
                {' '}
                <Typography variant="span">
                  Il s&apos;agit de petites cavités, qui se forment au niveau des phalanges. Elles
                  sont dues à l&apos;augmentation de la pression au niveau de l&apos;interligne
                  articulaire.
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                La condensation de l&apos;os sous-chondral :
                {' '}
                <Typography variant="span">
                  apparition d&apos;un liseré blanc en regard de l&apos;interligne articulaire.
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                Les ostéophytes :
                {' '}
                <Typography variant="span">
                  qui correspondent aux petits becs osseux.
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                La désaxation articulaire :
                {' '}
                <Typography variant="span">
                  en rapport avec une destruction asymétrique de l&apos;articulation.
                </Typography>
              </Typography>
            </List.Item>
          </List>
          <Figure
            maxWidth="max-w-[400px]"
            imageName="figure2-arthrosedesdoigts.jpg"
            caption="Figure 2. Radiographie d&apos;une main arthrosique : signes cardinaux d&apos;arthrose (pincements articulaires, géodes, condensation de l&apos;os sous-chondral et ostéophytes) associés à des désaxations articulaires"
            currentOpTitle="Arthrose des doigts"
          />
          <Typography>
            Notez qu&apos;il n&apos;existe pas de corrélation anatomo-clinique en cas
            d&apos;arthrose. Un patient peut présenter une arthrose importante sur les
            clichés, mais qui peut être très peu symptomatique.
          </Typography>
        </MainPart>
        <MainPart title="Les traitements médicaux et chirurgicaux pour traiter l&apos;arthrose des doigts">
          <Typography>
            Il existe deux types de traitements principaux pour l&apos;arthrose des
            doigts : le traitement médical et le traitement chirurgical. Le choix de
            l&apos;un ou de l&apos;autre dépend de la sévérité de la maladie.
          </Typography>
          <Typography size="mdx" weight="bold">Le traitement médical de l&apos;arthrose :</Typography>
          <Typography>
            Le traitement médical repose sur la gestion de la douleur et de
            l&apos;inflammation. Ainsi, le médecin pourra prescrire :
          </Typography>
          <List>
            <List.Item>
              <Typography weight="bold">
                Des antalgiques :
                {' '}
                <Typography variant="span">
                  pour soulager les douleurs.
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                Des anti-inflammatoires non stéroïdiens :
                {' '}
                <Typography variant="span">
                  à utiliser sur une courte durée. En effet, ils provoquent des
                  effets secondaires (ulcère gastrique, insuffisance rénale…).
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                Une attelle :
                {' '}
                <Typography variant="span">
                  à mettre la nuit en cas de crises douloureuses.
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                Une injection articulaire de corticoïde :
                {' '}
                <Typography variant="span">
                  qui a une efficacité temporaire.
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                Une injection articulaire d&apos;acide hyaluronique :
                {' '}
                <Typography variant="span">
                  Mais son efficacité n&apos;a pas été démontrée dans les petites
                  articulations de la main.
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                De la kinésithérapie :
                {' '}
                <Typography variant="span">
                  peut également être proposée. Les exercices permettent de mieux
                  gérer la douleur et de retrouver de la souplesse.
                </Typography>
              </Typography>
            </List.Item>
          </List>
          <Typography size="mdx" weight="bold">Le traitement chirurgical :</Typography>
          <Typography>
            L&apos;arthrose digitale peut présenter de multiples manifestations cliniques.
            Elle peut aller de la simple raideur articulaire, qui sera peu douloureuse, à
            une articulation fortement déviée, douloureuse et raide. S&apos;il y a un
            traitement chirurgical, il doit donc être adapté à chaque patient (chirurgie
            « à la carte »).
          </Typography>
          <Typography>
            Le traitement chirurgical est indiqué en cas d&apos;échec du traitement médical,
            et/ ou d&apos;emblée, dans les formes sévères d&apos;arthrose.
          </Typography>
          <Typography>
            L&apos;intervention chirurgicale est le plus souvent réalisée sous anesthésie
            locorégionale (seul le bras est endormi) et en ambulatoire. Il n&apos;y a donc
            pas d&apos;hospitalisation.
          </Typography>
          <Typography>
            Plusieurs interventions sont envisageables, en fonction de la gêne et de la
            localisation de l&apos;arthrose. Citons :
          </Typography>
          <List>
            <List.Item>
              <Typography weight="bold">
                Le débridement ou « nettoyage » de l&apos;articulation
                {' '}
                <Typography variant="span">
                  qui permet de retirer les nodules (Heberden ou Bouchard)
                  et un éventuel kyste mucoïde associé.
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                L&apos;arthrodèse :
                {' '}
                <Typography variant="span">
                  Cette opération consiste à bloquer définitivement l&apos;articulation.
                  Le cartilage résiduel est retiré et l&apos;articulation est ensuite
                  bloquée par des vis ou des broches. Ce traitement est choisi en cas
                  d&apos;arthrose inter-phalangienne distale douloureuse.
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                La mise en place d&apos;une prothèse :
                {' '}
                <Typography variant="span">
                  Elle permet de maintenir une mobilité articulaire sans douleur. Il
                  existe plusieurs types de prothèse sur le marché. Elles sont le plus
                  souvent posées en cas d&apos;arthrose de l&apos;articulation inter-phalangienne
                  proximale ou métacarpo-phalagienne. Le choix du type de prothèse est fait en
                  fonction des habitudes du chirurgien.
                </Typography>
              </Typography>
            </List.Item>
          </List>
          <Typography size="mdx" weight="bold">Les différents types de prothèses :</Typography>
          <Typography>
            Pour l&apos;arthrose des doigts, le chirurgien a donc le choix entre ces différentes
            prothèses :
          </Typography>
          <List>
            <List.Item>
              <Typography weight="bold">
                Les prothèses en silicone ou de Swanson
                {' '}
                <Typography variant="span">
                  Il s&apos;agit des plus anciennes prothèses de doigts (Figures 3 et 4). Elles
                  présentent cependant quelques inconvénients. Par exemple, la charnière de la
                  prothèse peut se rompre, ce qui nécessite le remplacement complet de la prothèse.
                  Une réaction osseuse à la prothèse peut également se produire. Appelée
                  « siliconite », elle est responsable d&apos;une destruction osseuse
                  (lyse osseuse avec formation de kystes).
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                Les prothèses métalliques à glissement ou à charnière :
                {' '}
                <Typography variant="span">
                  Il s&apos;agit de prothèses miniaturisées. Elles fonctionnent selon le même
                  principe que les prothèses de genou.
                </Typography>
              </Typography>
            </List.Item>
          </List>
          <Typography>
            Quel que soit le type de prothèse utilisée, il est demandé au patient d&apos;économiser
            sa main pour ne pas mettre en danger la longévité de sa prothèse. Une surveillance
            clinique et radiologique régulière est le plus souvent préconisée.
          </Typography>
          <div className="flex flex-col items-start space-x-0 space-y-8 mdx:flex-row mdx:space-x-8 mdx:space-y-0">
            <Figure
              maxWidth="max-w-[350px]"
              imageName="figure3-arthrosedesdoigts.jpg"
              caption="Figure 3. Prothèse en silicone ou de Swanson"
              currentOpTitle="Arthrose des doigts"
            />
            <Figure
              maxWidth="max-w-[250px]"
              imageName="figure4-arthrosedesdoigts.jpg"
              caption="Figure 4. Prothèse en silicone au niveau de l&apos;articulation inter-phalangienne proximale du majeur"
              currentOpTitle="Arthrose des doigts"
            />
          </div>
        </MainPart>
        <MainPart title="Les complications possibles après une chirurgie pour l&apos;arthrose des doigts">
          <Typography>
            Une intervention chirurgicale peut comporter des risques. Ceux-ci sont classés en
            deux catégories :
          </Typography>
          <Typography size="mdx" weight="bold">Les complications non spécifiques</Typography>
          <Typography>
            Ce sont les complications liées à l&apos;intervention en elle-même. Parmi
            elles, on peut citer :
          </Typography>
          <List>
            <List.Item>
              <Typography>
                L&apos;infection du site opératoire, qui peut nécessiter une intervention de
                nettoyage associée à la prise d&apos;antibiotiques.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Un hématome qui peut nécessiter une évacuation en cas de menace cutanée ou de
                compression nerveuse.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Une Algodystrophie. L&apos;apparition de cette complication est indépendante du
                type de chirurgie. Elle évolue en deux phases : la phase chaude, avec main
                gonflée, douloureuse et transpiration. Puis la phase froide, avec prédominance
                de la raideur. L&apos;évolution est généralement longue (12 à 18 mois). Des
                séquelles sont possibles (douleur et/ou raideur des articulations des doigts
                et/ou poignet et/ou épaule).
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Les accidents liés à l&apos;anesthésie
              </Typography>
            </List.Item>
          </List>
          <Typography size="mdx" weight="bold">Les complications spécifiques</Typography>
          <Typography>
            Elles concernent la pathologie en elle-même. On retrouve :
          </Typography>
          <List>
            <List.Item>
              <Typography>
                Une raideur et/ou une douleur séquellaire. Elle est fréquente en cas de chirurgie
                de débridement ou de « nettoyage » de l&apos;articulation, liée à la progression
                de l&apos;arthrose. Ces symptômes peuvent également se manifester après la mise
                en place d&apos;une prothèse articulaire, justifiant la réalisation de séances
                de rééducation.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                La rupture de charnière. Cela arrive en cas de prothèse en silicone ou de prothèse
                métallique à charnière. En cas de rupture de charnière en silicone, le remplacement
                de la prothèse n&apos;est indiqué qu&apos;en cas de gêne fonctionnelle majeure
                (douleur, flexion/extension du doigt impossible, déviation du doigt).
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                La luxation de la prothèse en cas de prothèse métallique à glissement. Elle
                nécessite le plus souvent une reprise chirurgicale.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Une réaction à la prothèse, comme une siliconite en cas de prothèse en silicone, ou
                une métallose en cas de prothèse en métal. Il s&apos;agit de la réaction de
                l&apos;organisme à la prothèse, qui demande là aussi le plus souvent une reprise
                chirurgicale.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                L&apos;échec d&apos;arthrodèse (pseudarthrodèse). L&apos;absence de consolidation
                osseuse demande la plupart du temps une chirurgie de reprise, avec une éventuelle
                greffe osseuse.
              </Typography>
            </List.Item>
          </List>
        </MainPart>
      </div>
    </div>
    <RandomOp type="mains" />
  </Layout>
);

export default ArthroseDesDoigts;
